import React, { useRef, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import API from "../constants/api"
import "../password.css"

const ProcessPasswordReset = ({ location }) => {
  const param = new URLSearchParams(location.search).get("token")
  const [password, setPassword] = useState("")
  const [hasError, setError] = useState("")
  const [confirm, setConfirm] = useState("")
  const [showConfirmPass, setShowConfirmPass] = useState(false)
  const [showPass, setShowPass] = useState(false)
  const [passreset, setPassreset] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const handleSubmit = () => {
    if (isProcessing) return
    if (confirm !== password) {
      setError("Please make sure your passwords match!")
      return
    }
    //strlen($password) < 6 && strlen($password) > 15
    if (password.length < 6 || password.length > 15) {
      setError(
        "Your password must be greater then 6 characters and less then 15 characters."
      )
      return
    }
    setIsProcessing(true)
    setError("")
    const formData = new FormData()
    formData.append("token", param)
    formData.append("password", password)
    fetch(`${API.SUPPORT}do_pass_reset.php`, {
      method: "POST",
      body: formData,
      headers: {
        "X-XSRF-Header": API.XSRF,
      },
    })
      .then(res => res.json())
      .then(res => {
        console.log("in here 83923", res)
        if ("error" in res) {
          setError(
            "Something went wrong please contact us via email service@addfriends.com"
          )
        } else {
          setIsProcessing(false)
          setPassreset(true)
        }
      })
  }
  return (
    <Layout>
      <SEO title="Choose Your Password." />
      <div className="section_form">
        <div className="section_background">
          <img src={"/af-bg.jpg"} alt="" />
        </div>
        <div className="block_form passCenter">
          <div className="block_form_wrapper">
            <>
              <div className="heading">
                {passreset ? (
                  <h2 className="title">
                    Pass Is <strong>Reset</strong>
                  </h2>
                ) : (
                  <h2 className="title">
                    New <strong>Password</strong>
                  </h2>
                )}

                {hasError !== "" && <div className="passError">{hasError}</div>}
              </div>
              {!passreset && (
                <div className="field">
                  <div className="row">
                    <div className="wrap">
                      <label htmlFor="email" className="label">
                        New Password
                      </label>
                      <div className="passResetInput">
                        <input
                          type={showPass ? "text" : "password"}
                          id="password"
                          className="input passreset"
                          value={password}
                          onChange={e => setPassword(e.target.value)}
                        />
                        <button
                          className="showpass"
                          onClick={() => setShowPass(prev => !prev)}
                        >
                          {showPass ? "HIDE" : "SHOW"}
                        </button>
                      </div>
                    </div>
                    <div className="wrap" style={{ marginTop: 10 }}>
                      <label htmlFor="confirm" className="label">
                        Confirm New Password
                      </label>
                      <div className="passResetInput">
                        <input
                          type={showConfirmPass ? "text" : "password"}
                          id="confirm"
                          className="input"
                          onChange={e => setConfirm(e.target.value)}
                        />
                        <button
                          className="showpass"
                          onClick={() => setShowConfirmPass(prev => !prev)}
                        >
                          {showConfirmPass ? "HIDE" : "SHOW"}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <button
                      className={
                        isProcessing ? "button_more processing" : "button_more"
                      }
                      onClick={handleSubmit}
                    >
                      Save
                      <div className="nk-spinner"></div>
                    </button>
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ProcessPasswordReset
